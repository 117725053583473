<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
import errorHandler from '../../../../helpers/errorHandler';
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Create Youtube Video",
  },
  mixins: [validationMixin],
  validations:{
    form:{
      link: {
        required,
      },
      name: {
        required,
      },
    }
  },
  data() {
    return {
      title: "Videos",
      items: [
        {
          text: "Work center",
          href: "/home",
        },
        {
          text: "Video form",
          href: "/sessions",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        link: null,
        chapter_id: null,
        lesson_id: null,
        must_attend:null,
        id: null
      },
      chapterError: null,
      lessonError: null,
      lockError: null,
      locked:[
        {
          text: 'Locked',
          value: true
        },
        {
          text: 'Unlocked',
          value: false
        }
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed:{
    chapters(){
      return this.$store.getters['option/chapters'];
    },
    lessons(){
      return this.$store.getters['option/lessons'];
    },
  },
  methods:{
    validate(){
      if(this.form.chapter_id){
        this.chapterError = true;
      }else{
        this.chapterError = false;
      }
      if(this.form.lesson_id){
        this.lessonError = true;
      }else{
        this.lessonError = false;
      }
      if(this.form.must_attend != null){
        this.lockError = true;
      }else{
        this.lockError = false;
      }
      return this.chapterError && this.lessonError && this.lockError;
    },
    handleSubmit() {
      this.submit = true;
      this.$v.$touch();
      if(this.validate() &&!this.$v.$anyError ){
        // requests for the create
        this.$store.dispatch("video/addYoutubeVideo",this.form).then(
           ()=>{
            this.$router.go(-1);
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
    handleUpdate(){
      this.submit = true;
      this.$v.$touch();
      if(this.validate() &&!this.$v.$anyError){
        // requests for the create
        this.$store.dispatch("video/editYoutubeVideo",this.form).then(
           ()=>{
            this.$router.go(-1);
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
    clearSelectError4(){
      this.lockError = null;
    },
    async clearSelectError5(){
      this.chapterError = null;
      this.form.lesson_id = null;
      await this.$store.dispatch('option/clearLessonsOption');
      await this.$store.dispatch('option/getChapterLessonsOption', this.form.chapter_id).then(()=>{}).catch( err =>{
        errorHandler.methods.errorMessage(err);
      });
    },
    clearSelectError6(){
      this.lessonError = null;
    },

  },
  async created(){
    await this.$store.dispatch('option/getChapterOption').then(()=>{}).catch( err =>{
      errorHandler.methods.errorMessage(err);
    });
    if(this.$route.params.id){
        this.type = 'edit';
        await this.$store.dispatch('option/clearLessonsOption');
        // await this.$store.dispatch('video/getYoutubeVideoInfo',this.$route.params.id).then( async token =>{
        //     this.form.chapter_id = token.chapter_id;
        //     await this.$store.dispatch('option/getChapterLessonsOption', this.form.chapter_id).then(()=>{}).catch( err =>{
        //       errorHandler.methods.errorMessage(err);
        //     });
        //     this.form.lesson_id = token.lesson_id;
        //     this.form.link = token.link;
        //     this.form.name = token.name;
        //     this.form.must_attend = token.must_attend;
        //     this.form.id = this.$route.params.id;
        // }).catch( err =>{
        //   errorHandler.methods.errorMessage(err);
        // })
    }else{
        this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create Video</h2>
                    <h2 v-if="type == 'edit'">Edit Video</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Link:"
                        label-for="input-D"
                    >
                        <input v-if="type == 'create' || (type == 'edit' && (form.link != null))" v-model="form.link" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.link.$error }" placeholder="Enter video link" />
                        <div v-if="submit && $v.form.link.$error" class="invalid-feedback">
                            <span v-if="!$v.form.link.required">This field is required.</span>
                            
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Name:"
                        label-for="input-D"
                    >
                        <input v-if="type == 'create' || (type == 'edit' && (form.name != null))" v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.name.$error }" placeholder="Enter video name" />
                        <div v-if="submit && $v.form.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.name.required">This field is required.</span>
                            
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group label="Must attend:" label-for="input-C" >
                        <b-form-select v-if="type == 'create' || (type == 'edit' && (form.must_attend != null))" v-model="form.must_attend" :options="this.locked" :state="lockError" @change="clearSelectError4"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group label="Chapter:" label-for="input-C" >
                        <b-form-select v-if="type == 'create' || (type == 'edit' && form.chapter_id != null)"  v-model="form.chapter_id" :options="this.chapters" :state="chapterError" @change="clearSelectError5"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="form.chapter_id">
                      <b-form-group label="Lesson:" label-for="input-C" >
                        <b-form-select v-if="type == 'create' || (type == 'edit' && form.lesson_id != null)" v-model="form.lesson_id" :options="this.lessons" :state="lessonError" @change="clearSelectError6"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>